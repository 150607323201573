import React from 'react';
import {apiFetch} from './services/networking/Api';
import {configPath} from './config/networking';
import {configFromResponse, createPlugin, logoFromResponse, nameFromResponse} from './services/plugin';
import PluginHeader from "./components/PluginHeader";

const App = props => {

    const pluginRef = React.useRef();

    const [pluginName, setPluginName] = React.useState();
    const [pluginLogo, setPluginLogo] = React.useState();

    React.useEffect(() => {
        const subDomain = window.location.hostname.split('.')[0];
        apiFetch(configPath(subDomain))
            .catch(() => {})
            .then(response => {
                setPluginName(nameFromResponse(response));
                setPluginLogo(logoFromResponse(response));
                createPlugin(pluginRef.current.id, configFromResponse(response));
            });
    }, []);

    return (
        <>
            <PluginHeader name={pluginName} logo={pluginLogo}/>
            <div ref={pluginRef} id={'sm-wp-root'} className={'padding-vertical'}/>
        </>
    );
};

export default App;
