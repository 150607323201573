import React from 'react';

const PluginHeader = props => {
    const {name, logo} = props;
    if (!name && !logo) return null;
    return (
        <div className={'padding-vertical'}>
            {logo ? <div className={'plugin-logo'}><img src={logo} alt={name}/></div> : (name ? <h1 className={'plugin-name'}>{name}</h1> : null)}
        </div>
    )
};

export default PluginHeader;
