
export const isNil = value => value === undefined || value === null || value === '';

export const isEmpty = value => isNil(value) || (Array.isArray(value) && value.length <= 0) || Object.keys(value).length <= 0;

export const flattenDeep = arr => {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
};

export const join = (arr, separator) => flattenDeep(arr).filter(n => n).join(separator)

export const pathFromDirectories = (...directories) => {
    return [...directories].filter(dir => dir).map(dir => (dir+'').replace(/(?:^\/*)(.*)(?:\b\/*$)/, '$1')).join('/');
};

export const queryFromParams = (params) => {
    if(isEmpty(params)) return '';
    const str = Object.keys(params).map(key => key + '=' + params[key]);
    return str.length > 0 ? '?' + str.join('&') : '';
};

export const parseJSON = str => {
    try {
        const config = str.replace(/\n|\t|\f|\r|\s{2,}/g, '');
        return JSON.parse(config);
    } catch(e) {
        return {};
    }
};
