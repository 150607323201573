import {defaultConfig} from '../config/plugin';
import _get from 'lodash/get';
import {join} from './utils'
import {HOST_URL} from '../config/networking'

export const createPlugin = (id, config) => {
    if(window.SmartMeetingWebsitePlugin) {
        try {
            new window.SmartMeetingWebsitePlugin(id, {
                ...defaultConfig,
                ...config
            });
        } catch(e) {

        }
    }
};

export const nameFromResponse = response => {
    return _get(response, 'body.plugin_name');
};

export const logoFromResponse = response => {
    const logo = _get(response, 'body.logo_url');
    if (logo) return join([HOST_URL, logo], '/');
    return null;
};

export const configFromResponse = response => {
    return {
        ..._get(response, 'body.config', {}),
        api: {
            key: _get(response, 'body.key', '')
        }
    };
};
