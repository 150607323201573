import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie9';
import 'core-js/features/array/find';

import './index.scss';

import App from './App';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
