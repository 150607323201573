
export const defaultConfig = {
    theme: {
        name: 'foundation_6',
        ui: {
            button: {
                colors: {
                    secondary: 'light'
                }
            },
            page: {
                title: {
                    tag: 'h2'
                },
                subtitle: {
                    tag: 'h3'
                }
            },
            section: {
                title: {
                    tag: 'h4'
                },
                subtitle: {
                    tag: 'h5'
                }
            }
        }
    },
    misc: {
        scroll: {
            enabled: true,
            offset: -30
        }
    }
};
